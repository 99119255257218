<h2 id="10023">10.0.23</h2>
<h4 id="jan222025">Jan 22, 2025</h4>
<h3 id="bugfixes">Changes</h3>
<ul>
<li>Incidents with Audatex estimates support direct incident transfers</li>
</ul>
<h2 id="10022">10.0.22</h2>
<h4 id="jan162025">Jan 16, 2025</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>Fixed query issue where insurances with long comments don't get retrieved</li>
</ul>
<h2 id="10021">10.0.21</h2>
<h4 id="jan142025">Jan 14, 2025</h4>
<h3 id="changes">Changes</h3>
<ul>
<li>Reduced query times for the insurance list page</li>
<li>Reduced query times for the insurance detail page</li>
</ul>
<h2 id="10020">10.0.20</h2>
<h4 id="jan062025">Jan 6, 2025</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>Tariff rules with the first criteria being inactive don't get applied</li>
</ul>
<h3 id="changes">Changes</h3>
<ul>
<li>Reduced query times for the incident behaviour state checks</li>
<li>Agreement number not required to edit incidents</li>
</ul>
<h2 id="10019">10.0.19</h2>
<h4 id="dec182024">Dec 18, 2024</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>AllButDeleted filter not displaying incidents awaiting Audatex estimate</li>
<li>Active status filter not displaying incidents awaiting Audatex estimate</li>
<li>Audatex estimate file link on incorrect charge section</li>
</ul>
<h3 id="features">Features</h3>
<ul>
<li>Manual form now gets displayed to enter non-fleet vehicle details for incidents submitted for Audatex estimate</li>
<li>Booking report can now search by booking status and booking transaction type</li>
<li>Added permission restricted toggle to prevent automatic incident status change when adding estimates to an incident</li>
</ul>
<h3 id="changes">Changes</h3>
<ul>
<li>Agreement number now optional for submitting Audatex estimate requests</li>
</ul>
<h2 id="10018">10.0.18</h2>
<h4 id="nov012024">Nov 1, 2024</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>Fixed issue where incident images could not be downloaded</li>
</ul>
<h2 id="10017">10.0.17</h2>
<h4 id="oct312024">Oct 31, 2024</h4>
<h3 id="changes">Changes</h3>
<ul>
<li>When creating or editing incidents, if the incident owning branch is Fleet, the agreement number won't be required</li>
<li>When creating or editing incidents with only one incident item, if the incident item type is Other, the agreement number won't be required</li>
<li>For repair status updates of completed incidents, the agreement number won't be required</li>
</ul>
<h2 id="10016">10.0.16</h2>
<h4 id="oct252024">Oct 25, 2024</h4>
<h3 id="features">Features</h3>
<ul>
<li>Integrated third party damage claim estimate functionality</li>
</ul>
<h3 id="changes">Changes</h3>
<ul>
<li>Agreement number field in Incident Editor is now permission protected</li>
</ul>
<h2 id="10015">10.0.15</h2>
<h4 id="oct142024">Oct 14, 2024</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>'Print' button for PLTN invoices does not work immediately after finalising the invoice and needs a page refresh for the printing to work</li>
</ul>
<h3 id="features">Features</h3>
<ul>
<li>Infrastructure addition to enable the reception of third party messages for damage claims</li>
</ul>
<h2 id="10014">10.0.14</h2>
<h4 id="sep172024">Sept 17, 2024</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>Customer invoice search not working</li>
<li>Pagination on Customer invoice search not working</li>
<li>Issue opening multiple tabs of Platform</li>
</ul>
<h3 id="features">Features</h3>
<ul>
<li>DoB update on person form</li>
<li>Updated homepage banner icons</li>
</ul>
<h2 id="10013">10.0.13</h2>
<h4 id="aug282024">Aug 28, 2024</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>New users not being created in Platform</li>
</ul>
<h2 id="10012">10.0.12</h2>
<h4 id="aug212024">Aug 21, 2024</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>Corporate tariff search not working in Quote Builder and Corporate Tariff page</li>
</ul>
<h2 id="10011">10.0.11</h2>
<h4 id="july192024">July 19, 2024</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>When raising a Platform invoice from an incident, a location must now be selected - however options do not include Contract Hire</li>
<li>When selecting a previous hirer for a qualification and they have any previously saved points from a past hire, when you delete these and select "no" to the any points/convictions question, after submission, the old convictions are still showing</li>
<li>Unable to create Platform invoices from incident estimates when it contains an estimate charge of type Paint and Materials</li>
<li>Unable to modify or submit invoices containing an estimate charge of type Paint and Materials</li>
<li>Infrastructure changes to the printing of insurance and credit notes</li>
</ul>
<h2 id="10010">10.0.10</h2>
<h4 id="july022024">July 2, 2024</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>Fixed issue where corporate tariff printing does not work</li>
</ul>
<h2 id="1009">10.0.9</h2>
<h4 id="june252024">June 25, 2024</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>Invoice editor form does not open for new invoices</li>
</ul>
<h2 id="1008">10.0.8</h2>
<h4 id="june212024">June 21, 2024</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>When entering a new qualification name, there is no automatic formatting applied to it</li>
<li>Line item types are missing from the invoice</li>
<li>Clicking on save in a new tariff catalogue group does not redirect user to created group </li>
<li>Clicking on save several times in a new tariff catalogue group creates multiple entries </li>
<li>Upgraded Stripe version </li>
</ul>
<h2 id="1007">10.0.7</h2>
<h4 id="june052024">June 5, 2024</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>Tariff catalogue pricing error when trying to enter pricing for a new tariff group</li>
<li>AMC Upload process not updating any of the vehicles if one of them fails the validation process</li>
</ul>
<h2 id="1006">10.0.6</h2>
<h4 id="may312024">May 31, 2024</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>Stripe payments not linked to Stripe customer accounts</li>
<li>PLTN Invoices printing in landscape mode</li>
</ul>
<h2 id="1005">10.0.5</h2>
<h4 id="may292024">May 29, 2024</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>Unable to add new groups to a draft corporate tariff</li>
<li>Incident gets completed with both estimates added together as the total despite one being deleted</li>
<li>Unable to amend customer on an incident </li>
<li>When creating an invoice via an estimate on an incident, the system does not carry over the 'Type' for each line item </li>
<li>Unable to print PLTN invoice </li>
<li>Unable to add new vehicles to tariff catalogue </li>
</ul>
<h2 id="1004">10.0.4</h2>
<h4 id="may172024">May 17, 2024</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>Excess Reducer Slider in the Quote Builder not working</li>
<li>Trackers cannot be saved if the tracker serial number already exist</li>
<li>Invalid suppliers being displayed when selecting a supplier for a new tracker</li>
<li>Incident estimate attachment files cannot be seen</li>
<li>Tariff Basic Rules, Groups Rules and Results cannot be dragged and dropped</li>
<li>Data-Exchange top menu is visible to all users</li>
</ul>
<h2 id="1003">10.0.3</h2>
<h4 id="may142024">May 14, 2024</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>AMC files not uploading</li>
<li>Misleading error message after uploading performance report</li>
</ul>
<h3 id="features">Features</h3>
<ul>
<li>Data Performance menu icon change</li>
</ul>
<h2 id="1002">10.0.2</h2>
<h4 id="may132024">May 13, 2024</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>Error message when saving a Corporation tariff</li>
<li>Unable to update a Corporation tariff name</li>
<li>Corporation tariff permissions updated</li>
<li>Unable to see transfer request details in incident activities</li>
<li>Unable to apply text filter in Insurance Qualification searches</li>
<li>Insurance Qualification list item names not being displayed correctly</li>
<li>Transfer requests counter not being displayed when requests requiring user attention exist</li>
<li>Unable to amend servicing templates in the Groups detail page</li>
</ul>
<h3 id="features">Features</h3>
<ul>
<li>New Data Performance page in Data Exchange Section with performance uploader restored</li>
<li>Default Corporate tariff ancillary charges updated</li>
</ul>
<h2 id="1001">10.0.1</h2>
<h4 id="may082024">May 8, 2024</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>Stripe WebHook not processing requests</li>
<li>Booking Customer names not showing in the Bookings page</li>
<li>Bookings Report Print button not working</li>
<li>Updated home page icon list</li>
</ul>

<h2 id="1000">10.0.0</h2>
<h4 id="february162024">February 16, 2024</h4>
<ul>
<li>Upgraded primary internal infrastructure for front-end and back-end by several major versions.</li>
</ul>
<h2 id="941">9.4.1</h2>
<h4 id="september242020">September 24, 2020</h4>
<h3 id="bugfixes">Bugfixes</h3>
<ul>
<li>Display vat information on invoice and credit notes.</li>
</ul>
<h2 id="940">9.4.0</h2>
<h4 id="september242020-1">September 24, 2020</h4>
<h3 id="features">Features</h3>
<ul>
<li>Allow invoice and credit note search on customer name.</li>
</ul>
<h2 id="939">9.3.9</h2>
<h4 id="september242020-2">September 24, 2020</h4>
<h3 id="bugfixes-1">Bugfixes</h3>
<ul>
<li>Correct required permission to post credit notes.</li>
</ul>
<h2 id="938">9.3.8</h2>
<h4 id="september232020">September 23, 2020</h4>
<h3 id="bugfixes-2">Bugfixes</h3>
<ul>
<li>Improve labeling of tax rates within invoices and credit notes.</li>
</ul>
<h2 id="937">9.3.7</h2>
<h4 id="september232020-1">September 23, 2020</h4>
<h3 id="bugfixes-3">Bugfixes</h3>
<ul>
<li>Fixed issue that allowed all users to link customer directly from invoice and credit note.</li>
</ul>
<h2 id="936">9.3.6</h2>
<h4 id="september222020">September 22, 2020</h4>
<h3 id="features-1">Features</h3>
<ul>
<li>Allow incidents with posted invoices to be closed without providing a manual invoice number.</li>
</ul>
<h3 id="bugfixes-4">Bugfixes</h3>
<ul>
<li>Maintain invoice incident reference when invoice has been edited.</li>
<li>Correctly disable actions on invoice and credit note modification.</li>
</ul>
<h2 id="935">9.3.5</h2>
<h4 id="september222020-1">September 22, 2020</h4>
<h3 id="bugfixes-5">Bugfixes</h3>
<ul>
<li>Display description on both invoice and credit note.</li>
</ul>
<h2 id="934">9.3.4</h2>
<h4 id="september222020-2">September 22, 2020</h4>
<h3 id="bugfixes-6">Bugfixes</h3>
<ul>
<li>Allow multiple addresses on customer when printing invoices.</li>
</ul>
<h2 id="933">9.3.3</h2>
<h4 id="september222020-3">September 22, 2020</h4>
<h3 id="bugfixes-7">Bugfixes</h3>
<ul>
<li>Fixed issue where datepicker was selecting in local timezone then converting to UTC but the api expects local zone.</li>
</ul>
<h2 id="932">9.3.2</h2>
<h4 id="september222020-4">September 22, 2020</h4>
<h3 id="bugfixes-8">Bugfixes</h3>
<ul>
<li>Remove required admin permission to add invoices to incident estimates.</li>
</ul>
<h2 id="931">9.3.1</h2>
<h4 id="september222020-5">September 22, 2020</h4>
<h3 id="bugfixes-9">Bugfixes</h3>
<ul>
<li>Correct issue that didnt allow incidents to be created with no linked vehicle via the fleet app.</li>
</ul>
<h2 id="930">9.3.0</h2>
<h4 id="september212020">September 21, 2020</h4>
<h3 id="features-2">Features</h3>
<ul>
<li>Notify fleet admins when the amc upload passes or fails.</li>
</ul>
<h2 id="921">9.2.1</h2>
<h4 id="september212020-1">September 21, 2020</h4>
<h3 id="bugfixes-10">Bugfixes</h3>
<ul>
<li>Improve draft display on invoice and credit print outs.</li>
<li>Allow all users to access invoice module.</li>
</ul>
<h2 id="920">9.2.0</h2>
<h4 id="september212020-2">September 21, 2020</h4>
<h3 id="features-3">Features</h3>
<ul>
<li>Add permissions to allow users to link/unlink Sage accounts.</li>
<li>Restrict what types of invoices a user can create based on their permissions and base location.</li>
</ul>
<h2 id="911">9.1.1</h2>
<h4 id="september182020">September 18, 2020</h4>
<h3 id="bugfixes-11">Bugfixes</h3>
<ul>
<li>Correct draft label in invoice print out.</li>
</ul>
<h2 id="910">9.1.0</h2>
<h4 id="september182020-1">September 18, 2020</h4>
<h3 id="features-4">Features</h3>
<ul>
<li>Improve display of Sage linked accounts.</li>
<li>Make it easier to link an account straight from an invoice.</li>
<li>Add credit note document.</li>
<li>Allow Sage accounts to be unlinked, this action will not effect previously posted invoices or credit notes.</li>
<li>Alert the user when trying to link a Sage account which is already linked to a customer.</li>
</ul>
<h2 id="903">9.0.3</h2>
<h4 id="september172020">September 17, 2020</h4>
<h3 id="bugfixes-12">Bugfixes</h3>
<ul>
<li>Correct issue that only displayed one company on search.</li>
<li>Correct onfleet datepicker on vehicle add form.</li>
</ul>
<h2 id="902">9.0.2</h2>
<h4 id="september162020">September 16, 2020</h4>
<h3 id="bugfixes-13">Bugfixes</h3>
<ul>
<li>Correct styling on some input fields.</li>
</ul>
<h2 id="901">9.0.1</h2>
<h4 id="september152020">September 15, 2020</h4>
<h3 id="bugfixes-14">Bugfixes</h3>
<ul>
<li>Correct issue that caused rules not to load.</li>
</ul>
<h2 id="900">9.0.0</h2>
<h4 id="september152020-1">September 15, 2020</h4>
<h3 id="features-5">Features</h3>
<ul>
<li>Add print out for invoices.</li>
<li>Improve initial load performance by decreasing main bundle size.</li>
<li>Complete charge types on invoices and credits line items.</li>
<li>Invoices are now owned by a location.</li>
<li>Migrate fleet groups and vehicle pages to new UI.</li>
<li>Allow users to bulk set corporate excess mileage.</li>
<li>Set default excess mileage on corporate tariff creation.</li>
<li>Improve vehicle filtering when linking vehicles to incidents.</li>
<li>Improve ui styling with reusable components.</li>
<li>Global invoicing module.</li>
<li>Allow invoices to be generated from incident estimates.</li>
<li>Allow invoices to be generated for incident with excess.</li>
</ul>
<h2 id="8118">8.1.18</h2>
<h4 id="september82020">September 8, 2020</h4>
<h3 id="bugfixes-15">Bugfixes</h3>
<ul>
<li>Fixed issue that caused performance targets request to fail.</li>
</ul>
<h2 id="8117">8.1.17</h2>
<h4 id="september32020">September 3, 2020</h4>
<h3 id="bugfixes-16">Bugfixes</h3>
<ul>
<li>Add ChipInGlass option to incident item types.</li>
</ul>
<h2 id="8116">8.1.16</h2>
<h4 id="august52020">August 5, 2020</h4>
<h3 id="bugfixes-17">Bugfixes</h3>
<ul>
<li>Correct order issue of servicing schedule.</li>
</ul>
<h2 id="8115">8.1.15</h2>
<h4 id="august42020">August 4, 2020</h4>
<h3 id="bugfixes-18">Bugfixes</h3>
<ul>
<li>Improve performance of customer endpoints.</li>
</ul>
<h2 id="8114">8.1.14</h2>
<h4 id="august42020-1">August 4, 2020</h4>
<h3 id="bugfixes-19">Bugfixes</h3>
<ul>
<li>Fix issue where permissions would not reappear when updating current permissions.</li>
</ul>
<h2 id="8113">8.1.13</h2>
<h4 id="august32020">August 3, 2020</h4>
<h3 id="bugfixes-20">Bugfixes</h3>
<ul>
<li>Readd permission bulk delete endpoint.</li>
</ul>
<h2 id="8112">8.1.12</h2>
<h4 id="july312020">July 31, 2020</h4>
<h3 id="bugfixes-21">Bugfixes</h3>
<ul>
<li>Allow defleeted vehicles to be linked to incidents.</li>
</ul>
<h2 id="8111">8.1.11</h2>
<h4 id="july302020">July 30, 2020</h4>
<h3 id="bugfixes-22">Bugfixes</h3>
<ul>
<li>Vehicle distance must now be greater than its current when creating a new incident.</li>
</ul>
<h2 id="8110">8.1.10</h2>
<h4 id="july302020-1">July 30, 2020</h4>
<h3 id="bugfixes-23">Bugfixes</h3>
<ul>
<li>Improve speed of corporate tariff requests.</li>
</ul>
<h2 id="819">8.1.9</h2>
<h4 id="july292020">July 29, 2020</h4>
<h3 id="bugfixes-24">Bugfixes</h3>
<ul>
<li>Tracking activites for merged incidents.</li>
</ul>
<h2 id="818">8.1.8</h2>
<h4 id="july282020">July 28, 2020</h4>
<h3 id="bugfixes-25">Bugfixes</h3>
<ul>
<li>Suppliers cannot be added with the same name.</li>
</ul>
<h2 id="817">8.1.7</h2>
<h4 id="july242020">July 24, 2020</h4>
<ul>
<li>No entries.</li>
</ul>
<h2 id="816">8.1.6</h2>
<h4 id="july232020">July 23, 2020</h4>
<h3 id="bugfixes-26">Bugfixes</h3>
<ul>
<li>Add insurance claim incidents to dashboard.</li>
<li>Allow incidents to be filtered on insurance claim.</li>
</ul>
<h2 id="815">8.1.5</h2>
<h4 id="july162020">July 16, 2020</h4>
<h3 id="bugfixes-27">Bugfixes</h3>
<ul>
<li>Can now merge items into existing incidents</li>
</ul>
<h2 id="814">8.1.4</h2>
<h4 id="july142020">July 14, 2020</h4>
<h3 id="bugfixes-28">Bugfixes</h3>
<ul>
<li>Correct date format in booking emails to dd/MM/yyyy.</li>
</ul>
<h2 id="813">8.1.3</h2>
<h4 id="june292020">June 29, 2020</h4>
<h3 id="bugfixes-29">Bugfixes</h3>
<ul>
<li>Correct issue where some users thumbnails were not displaying.</li>
</ul>
<h2 id="812">8.1.2</h2>
<h4 id="june292020-1">June 29, 2020</h4>
<h3 id="bugfixes-30">Bugfixes</h3>
<ul>
<li>Correct telephone number mapping of online customers.</li>
<li>Allow online customer surname search.</li>
</ul>
<h2 id="811">8.1.1</h2>
<h4 id="june232020">June 23, 2020</h4>
<h3 id="bugfixes-31">Bugfixes</h3>
<ul>
<li>Allow all locations to be selected in incident creation form.</li>
</ul>
<h2 id="810">8.1.0</h2>
<h4 id="june192020">June 19, 2020</h4>
<h3 id="features-6">Features</h3>
<ul>
<li>No longer take deposit with online bookings. All deposits should now be taken at the desk.</li>
<li>Improve amc importing process.</li>
<li>Minor improvements to insurance requests endpoints.</li>
</ul>
<h2 id="807">8.0.7</h2>
<h4 id="june182020">June 18, 2020</h4>
<h3 id="bugfixes-32">Bugfixes</h3>
<ul>
<li>Notes now sort newest to oldest again.</li>
</ul>
<h2 id="806">8.0.6</h2>
<h4 id="june182020-1">June 18, 2020</h4>
<h3 id="bugfixes-33">Bugfixes</h3>
<ul>
<li>Corrected image upload url on new incidents.</li>
</ul>
<h2 id="805">8.0.5</h2>
<h4 id="june182020-2">June 18, 2020</h4>
<ul>
<li>No entries.</li>
</ul>
<h2 id="804">8.0.4</h2>
<h4 id="june182020-3">June 18, 2020</h4>
<h3 id="bugfixes-34">Bugfixes</h3>
<ul>
<li>Correct issue not allowing odometer entries to be added.</li>
</ul>
<h2 id="803">8.0.3</h2>
<h4 id="june172020">June 17, 2020</h4>
<h3 id="bugfixes-35">Bugfixes</h3>
<ul>
<li>Re-enable amc uploading.</li>
</ul>
<h2 id="802">8.0.2</h2>
<h4 id="june172020-1">June 17, 2020</h4>
<h3 id="bugfixes-36">Bugfixes</h3>
<ul>
<li>Resolve issue where notifications didnt take the user to the correct page.</li>
</ul>
<h2 id="801">8.0.1</h2>
<h4 id="june162020">June 16, 2020</h4>
<h3 id="bugfixes-37">Bugfixes</h3>
<ul>
<li>Correct users ability to update current location.</li>
</ul>
<h2 id="800">8.0.0</h2>
<h4 id="june152020">June 15, 2020</h4>
<h3 id="features-7">Features</h3>
<ul>
<li>Migrate from asp .net 4.5 to asp net core 3.0. Vastly improves reliability and  performance of the system.</li>
</ul>
<h2 id="7101">7.10.1</h2>
<h4 id="june92020">June 9, 2020</h4>
<h3 id="bugfixes-38">Bugfixes</h3>
<ul>
<li>Resolved issue where split incident item button was always disabled.</li>
</ul>
<h2 id="7100">7.10.0</h2>
<h4 id="june82020">June 8, 2020</h4>
<h3 id="features-8">Features</h3>
<ul>
<li>Fleet admins can now remove images from incidents.</li>
<li>Can now drag and drop incident item images to different items.</li>
</ul>
<h2 id="790">7.9.0</h2>
<h4 id="may282020">May 28, 2020</h4>
<h3 id="features-9">Features</h3>
<ul>
<li>Can now merge incident items with new incidents.</li>
<li>Moved merging to existing incident into the item editor.</li>
<li>Fleet admins can now add incidents directly from Platform.</li>
</ul>
<h2 id="784">7.8.4</h2>
<h4 id="may272020">May 27, 2020</h4>
<h3 id="bugfixes-39">Bugfixes</h3>
<ul>
<li>Correctly set VAT type when using estimate catalogue.</li>
</ul>
<h2 id="783">7.8.3</h2>
<h4 id="may152020">May 15, 2020</h4>
<h3 id="bugfixes-40">Bugfixes</h3>
<ul>
<li>Correctly redirect to servicing entry when created from incident estimate.</li>
</ul>
<h2 id="782">7.8.2</h2>
<h4 id="may142020">May 14, 2020</h4>
<h3 id="bugfixes-41">Bugfixes</h3>
<ul>
<li>Correct vehicle distance void notification copy.</li>
<li>Correctly reload vehicle distances list when entry is voided.</li>
</ul>
<h2 id="781">7.8.1</h2>
<h4 id="may142020-1">May 14, 2020</h4>
<h3 id="bugfixes-42">Bugfixes</h3>
<ul>
<li>Resolved issue where insurance excess would not clear when unchecked in tariff quotation builder.</li>
</ul>
<h2 id="780">7.8.0</h2>
<h4 id="may142020-2">May 14, 2020</h4>
<h3 id="features-10">Features</h3>
<ul>
<li>Add Sage customer account linking.</li>
<li>Add daily recalculation of servicing schedule forecast dates.</li>
</ul>
<h2 id="772">7.7.2</h2>
<h4 id="may42020">May 4, 2020</h4>
<h3 id="bugfixes-43">Bugfixes</h3>
<ul>
<li>Improve Eu rate labelling.</li>
</ul>
<h2 id="771">7.7.1</h2>
<h4 id="april282020">April 28, 2020</h4>
<h3 id="bugfixes-44">Bugfixes</h3>
<ul>
<li>Correct notes not updating when switching between incidents and servicing entries.</li>
</ul>
<h2 id="770">7.7.0</h2>
<h4 id="april222020">April 22, 2020</h4>
<h3 id="features-11">Features</h3>
<ul>
<li>Users with the correct permissions can now edit incident item details.</li>
<li>Users can now create incidents.</li>
</ul>
<h3 id="bugfixes-45">Bugfixes</h3>
<ul>
<li>Correct default ordering of various items, including incident items and servicing items.</li>
</ul>
<h2 id="763">7.6.3</h2>
<h4 id="april222020-1">April 22, 2020</h4>
<h3 id="bugfixes-46">Bugfixes</h3>
<ul>
<li>Remove broken servicing entry extra details, generic notes on the servicing entry or per task extra details should be used.</li>
<li>Correct default type selection on incident estimate and servicing entry charge items.</li>
</ul>
<h2 id="762">7.6.2</h2>
<h4 id="april212020">April 21, 2020</h4>
<h3 id="bugfixes-47">Bugfixes</h3>
<ul>
<li>Improve tracker display permissions.</li>
</ul>
<h2 id="761">7.6.1</h2>
<h4 id="april202020">April 20, 2020</h4>
<h3 id="bugfixes-48">Bugfixes</h3>
<ul>
<li>Correct date format on tracker importer.</li>
</ul>
<h2 id="760">7.6.0</h2>
<h4 id="april172020">April 17, 2020</h4>
<h3 id="features-12">Features</h3>
<ul>
<li>Add importer for existing tracker details.</li>
<li>Add tracker global list to view all trackers across the fleet.</li>
<li>Add mileage to quotation builder, allow users to increase mileage from within dialog.</li>
</ul>
<h2 id="753">7.5.3</h2>
<h4 id="april152020">April 15, 2020</h4>
<h3 id="bugfixes-49">Bugfixes</h3>
<ul>
<li>Correct responsive styles of quotation dialog.</li>
</ul>
<h2 id="752">7.5.2</h2>
<h4 id="april92020">April 9, 2020</h4>
<h3 id="bugfixes-50">Bugfixes</h3>
<ul>
<li>Correct changelog generation.</li>
</ul>
<h2 id="751">7.5.1</h2>
<h4 id="april92020-1">April 9, 2020</h4>
<h3 id="bugfixes-51">Bugfixes</h3>
<ul>
<li>Correct permission checks on EU rates.</li>
</ul>
<h2 id="750">7.5.0</h2>
<h4 id="april82020">April 8, 2020</h4>
<h3 id="features-13">Features</h3>
<ul>
<li><p>Add user change logs, will highlight key updates to Platform.</p></li>
<li><p>Batch link all older incidents to the correct vehicles.</p></li>
<li><p>Add additional mileage and EU rates to tariff optional extras.</p></li>
</ul>